<template>
	<transition name="modal">
		<v-dialog v-model="isOpen" width="445" persistent>
			<v-card class="pa-5 rounded-small">
				<slot name="close-button" :close="closeModal">
					<div class="d-flex justify-end">
						<v-btn icon @click="closeModal">
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</div>
				</slot>
				<slot name="body">
					<div class="modal__body"></div>
				</slot>
			</v-card>
		</v-dialog>
	</transition>
</template>

<script>
export default {
	name: 'Modal',
	props: {
		value: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isOpen: this.value,
		}
	},
	mounted() {
		window.addEventListener('keydown', this.handleKeyDown)
	},
	beforeDestroy() {
		window.removeEventListener('keydown', this.handleKeyDown)
	},
	watch: {
		isOpen(newValue) {
			this.$emit('input', newValue)
		},
		value(newValue) {
			this.isOpen = newValue
		},
	},
	methods: {
		closeModal() {
			this.isOpen = false
			this.$emit('close')
		},
		handleKeyDown(event) {
			// esc key
			if (event.keyCode === 27) {
				this.closeModal()
			}
		},
	},
}
</script>

<style lang="scss" scoped>
/* transition */
.modal-enter,
.modal-leave-to {
	opacity: 0;
}
.modal-enter-active .modal__container,
.modal-leave-active .modal__container {
	opacity: 1;
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}
.modal-enter-to .modal__container,
.modal-leave .modal__container {
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
}
</style>
